<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="closeDialog()"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <template v-if="p01">
        <div class="gray-box">
          <div class="form-inp sm">
            <v-select
              :items="PLT018"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="evalPaper.LM_EVA_TY"
              :disabled="USE_YN ? true : false"
            >
              <template v-slot:label>
                유형타입
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              disabled
              :items="PLT021"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택하세요"
            ></v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name"
            label="시험지명"
            outlined
            hide-details
            v-model="evalPaper.LM_EVA_NM"
            :disabled="USE_YN ? true : false"
            >
            <template v-slot:label>
              시험지명
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-text-field>
          <div class="mt-3">
            <v-text-field
              class="form-inp sm"
              name="name" label="총점" outlined hide-details v-model="evalPaper.LM_EVA_SUM" disabled>
            </v-text-field>
            <div class="form-inp sm ml-2">
              <v-select
                :items="PLT022"
                item-text="text"
                item-value="value"
                outlined
                hide-details
                label="질문랜덤"
                placeholder="선택하세요"
                v-model="evalPaper.LM_EVA_RM_YN"
                :disabled="USE_YN ? true : false"
              >
                <template v-slot:label>
                질문랜덤
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-textarea
            class="textarea-type0"
            label="설명"
            no-resize
            outlined
            v-model="evalPaper.LM_EVA_COMM"
            max="500"
            :disabled="USE_YN ? true : false"
          ></v-textarea>
        </div>
      </template>
      <template v-if="p02">
        <div class="d-flex gray-box">
          <div class="form-inp sm">
            <v-select
              :items="PLT019"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형"
              placeholder="선택하세요"
              v-model="lmQs.LM_QS_TY"
              :disabled="USE_YN ? true : false"
            >
            <template v-slot:label>
              유형
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="PLT021"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택하세요"
              v-model="lmQs.LM_QS_YN"
              disabled
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="PLT020"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="난이도"
              placeholder="선택하세요"
              v-model="lmQs.LM_QS_DIC"
              :disabled="USE_YN ? true : false"
            >
            <template v-slot:label>
              난이도
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="배점" outlined hide-details
            type="number"
            min="0"
            max="999"

            v-model="lmQs.LM_QS_TY_SC"
            >
            <template v-slot:label>
              배점
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="mt-2">
          <v-textarea
          label="문항내용"
          no-resize
          outlined
          hide-details
          rows="2"
          max="1000"
          v-model="lmQs.LM_QS_NM"
        >
          <template v-slot:label>
            문항내용
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
        </v-textarea>
        </div>
        <div class="mt-2">
          <v-textarea
          label="정답설명"
          no-resize
          outlined
          hide-details
          rows="2"
          v-model="lmQs.LM_QS_ANS_COMM"
          max="1000"
        >
          <template v-slot:label>
            정답설명
            <v-icon color="#EF9191">mdi-circle-small</v-icon>
          </template>
        </v-textarea>
        </div>

        <div class="mt-3">
          <h2 class="tit-h2 d-flex">문항 및 보기 등록
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd1')" outlined class="btn-point" @click="addlmQsRow()" :disabled="USE_YN ? true : false">추가</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete1')" outlined class="btn-etc ml-2" @click="dellmQsRow()" :disabled="USE_YN ? true : false">삭제</v-btn>
            </div>
          </h2>
          <div class="table-form mt-2">
            <table>
              <caption class="hide"> 항목</caption>
              <colgroup>
                <col width="40px">
                <col width="40px">
                <col width="50px">
                <col width="">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">정답</th>
                  <th scope="col">NO</th>
                  <th scope="col">보기내용</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lmVe, index) in lmVeList" :key="index">
                  <td class="text-center">
                    <v-checkbox
                      :on-icon="'svg-chk-on'"
                      :off-icon="'svg-chk-off'"
                      hide-details
                      :id="'lmVe_'+index"
                      name="lmVe"
                      v-model="lmVe.isChecked"
                      :disabled="USE_YN ? true : false"
                      >
                    </v-checkbox>
                  </td>
                  <td class="text-center">
                    <v-checkbox
                      :on-icon="'svg-chk-on'"
                      :off-icon="'svg-chk-off'"
                      hide-details
                      :id="'lmVe_ans_'+index"
                      name="lmVe_ans"
                      true-value="Y"
                      false-value="N"
                      v-model="lmVe.LM_ANS_YN"
                      :disabled="USE_YN ? true : false"
                      >
                    </v-checkbox>
                  </td>
                  <td class="text-center">{{index + 1}}</td>
                  <td>
                    <v-text-field
                      class="form-inp full"
                      name="name" label="보기내용" outlined hide-details
                      v-model="lmVe.LM_QS_VE_RT"
                      max="1000"
                      :disabled="USE_YN ? true : false"
                    >
                    <template v-slot:label>
                      보기내용
                      <v-icon color="#EF9191">mdi-circle-small</v-icon>
                    </template>
                    </v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="p03">
         <div class="d-flex gray-box">
          <div class="form-inp sm">
            <v-select
              :items="PLT018"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형 타입"
              placeholder="선택하세요"
              v-model="propsEvlPaper.LM_EVA_TY"
              disabled
            ></v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="PLT021"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="사용여부"
              placeholder="선택하세요"
              :value="propsEvlPaper.USE_COUNT > 0? 'Y' : 'N'"
              disabled
            ></v-select>
          </div>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="시험지명" outlined hide-details
            v-model="propsEvlPaper.LM_EVA_NM"
            disabled>
          </v-text-field>
          <v-text-field
            class="form-inp sm ml-2"
            name="name" label="총점" outlined hide-details
            v-model="getSc"
            disabled>
          </v-text-field>
        </div>
        <div class="mt-3">
          <h2 class="tit-h2 d-flex">문항 및 보기 등록
            <div class="ml-auto">
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnAdd2')" outlined class="btn-point" @click="$emit('addrow')" :disabled="USE_YN ? true : false">추가</v-btn>
              <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDelete2')" outlined class="btn-etc ml-2" @click="delEvlRstRow()" :disabled="USE_YN ? true : false">삭제</v-btn>
            </div>
          </h2>
          <div class="table-form mt-2">
            <table>
              <caption class="hide">항목</caption>
              <colgroup>
                <col width="40px">
                <col width="">
                <col width="">
                <col width="">
                <col width="">
              </colgroup>
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">NO</th>
                  <th scope="col">문항</th>
                  <th scope="col">점수</th>
                  <th scope="col">정렬</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in evlRstList" :key="index">
                  <td>
                    <v-checkbox
                      :on-icon="'svg-chk-on'"
                      :off-icon="'svg-chk-off'"
                      hide-details
                      v-model="item.isChecked"
                      :disabled="USE_YN ? true : false"
                      >
                    </v-checkbox>
                  </td>
                  <td class="text-center">{{index + 1}}</td>
                  <td>{{item.LM_QS_NM}}</td>
                  <td>{{item.LM_QS_TY_SC}}</td>
                  <td>{{item.LM_EVA_RST_OD}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="p04">
        <div class="qa-report" style="height:auto">
          <template v-for="(item, index) in preEvlRstData">
            <dl :key="index">
              <dt>{{index + 1}}. {{item.LM_QS_NM}}({{item.LM_QS_TY_SC}}점)</dt>
              <dd class="mt-3">
                <template v-for="(ve, index2) in preViewList" >
                  <div v-if="item.LM_QS_ID == ve.LM_QS_ID" :key="index2">
                    <!-- 서술형 -->
                    <template v-if="ve.LM_QS_TY === '20'">
                      <div>
                        <v-text-field
                          class="form-inp full"
                          name="name" placeholder="직접입력" id="id" outlined hide-details disabled>
                        </v-text-field>
                      </div>
                    </template>
                    <!-- 객관식, 체크박스 -->
                    <template v-else>
                      <div class="mb-2">
                        {{ve.LM_QS_VE_RT}}
                      </div>
                    </template>
                  </div>
                </template>

              </dd>
            </dl>
          </template>
         </div>
      </template>
      </div>
      <div class="text-right mt-3">
        <template v-if="!p04">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave')" outlined class="btn-point ml-2" @click="common_confirm('저장하시겠습니까?',processRtn, '', null,'','done')" :disabled="USE_YN ? true : false">저장</v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/store/apiUtil.js"
import {mixin} from "@/mixin/mixin.js"

export default {
  name: "MENU_M410101P01",
  mixins : [mixin],
  props: {
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    p03: {
      type: Boolean,
      default: false,
    },
    p04: {
      type: Boolean,
      default: false,
    },

    headerTitle: {
      type: String,
      default: '제목',
    },
    PLT018 : [],
    PLT019 : [],
    PLT020 : [],
    PLT021 : [],
    PLT022 : [],
    isModify : {
      type : Boolean,
      default: false,
    },
    propsEvlPaper : {
        LM_EVA_NM : "",
        LM_EVA_TY : "",
        LM_EVA_RM_YN : "",
        QA_EVA_CN : "",
        LM_EVA_SUM : "",
        LM_EVA_COMM : "",
        LM_EVA_ID : "",
    },
    propsLmQs : {

    },
    propsEvlRst : [],
    preEvlRstData : [],
    preViewList : [],
    aspNewCustKey : {
      type : String
    }
  },
  data: () => ({
    valid: true,
    footerHideTitle: '닫기',
    evalPaper : {
      LM_EVA_NM : "",
      LM_EVA_TY : "",
      LM_EVA_RM_YN : "",
      QA_EVA_CN : "",
      LM_EVA_SUM : "",
      LM_EVA_RT : "",
      LM_EVA_ID : "",
    },
    lmQs : {
      LM_QS_ID : "",
      QA_TY_L_CD : "",
      QA_TY_M_CD : "",
      LM_QS_NM : "",
      LM_QS_DIC : "",
      LM_QS_DIC_SC : "",
      LM_QS_YN : "",
    },
    lmVeList : [
    ],
    delLmVeList : [

    ],
    evlRstList : [],
    USE_YN : false,

  }),
  created(){
    this.getPropsEvlPaper();
    this.getPropsLmQs();
    this.getPropsEvlRst();
  },
  watch:{
    propsEvlPaper : function(){
      this.getPropsEvlPaper();
    },
    propsLmQs : function(){
      this.getPropsLmQs();
    },
    propsEvlRst : function(){
      this.getPropsEvlRst();
    }
  },
  methods: {
    getPropsEvlRst(){
      // this.propsEvlRst.map(e => this.evlRstList.push(e));
      this.evlRstList = this.propsEvlRst;
    },
    getPropsEvlPaper(){
      if(this.propsEvlPaper != null){
        this.evalPaper.LM_EVA_NM = this.propsEvlPaper.LM_EVA_NM;
        this.evalPaper.LM_EVA_TY = this.propsEvlPaper.LM_EVA_TY;
        this.evalPaper.LM_EVA_RM_YN = this.propsEvlPaper.LM_EVA_RM_YN;
        this.evalPaper.LM_EVA_SUM = this.propsEvlPaper.LM_EVA_SUM;
        this.evalPaper.LM_EVA_COMM = this.propsEvlPaper.LM_EVA_COMM;
        this.evalPaper.LM_EVA_ID = this.propsEvlPaper.LM_EVA_ID;
        this.USE_YN = this.propsEvlPaper.USE_COUNT > 0 ? true : false;
      }
    },
    getPropsLmQs(){
      if(this.propsLmQs != null){
        this.lmQs.LM_QS_ID = this.propsLmQs.LM_QS_ID;
        this.lmQs.LM_QS_TY = this.propsLmQs.LM_QS_TY;
        this.lmQs.LM_QS_NM = this.propsLmQs.LM_QS_NM;
        this.lmQs.LM_QS_DIC = this.propsLmQs.LM_QS_DIC;
        this.lmQs.LM_QS_TY_SC = this.propsLmQs.LM_QS_TY_SC;
        this.lmQs.LM_QS_ANS_COMM = this.propsLmQs.LM_QS_ANS_COMM;
        this.lmQs.LM_QS_YN = this.propsLmQs.LM_QS_YN;
        this.USE_YN = this.propsLmQs.USE_COUNT > 0 ? true : false;
        this.selectRtnlmVe();
      }
    },
    processRtn(){
      if(this.p01){
        this.insertRtnEvlPaper();
      }else if(this.p02){
        this.insertRtnlmQs();
      }else if(this.p03){
        this.$emit('submit', this.evlRstList);
      }
    },
    async insertRtnEvlPaper(){
      if(this.evalPaper.LM_EVA_TY == undefined || this.evalPaper.LM_EVA_TY == ''){
        this.warningAlert("유형타입은 필수입니다.");
        return;
      }

      if(this.evalPaper.LM_EVA_NM == undefined || this.evalPaper.LM_EVA_NM == ''){
        this.warningAlert("시험지명은 필수입니다.");
        return;
      }

      if(this.evalPaper.LM_EVA_RM_YN == undefined || this.evalPaper.LM_EVA_RM_YN == ''){
        this.warningAlert("질문랜덤은 필수입니다.");
        return;
      }

      await api.post('api/phone/lm/evl-paper-manage/regist',{
        ['ASP_NEWCUST_KEY']: this.aspNewCustKey,
        ['LM_EVA_TY']: this.evalPaper.LM_EVA_TY,
        ['LM_EVA_NM']: this.evalPaper.LM_EVA_NM,
        ['LM_EVA_RM_YN'] : this.evalPaper.LM_EVA_RM_YN,
        ['LM_EVA_SUM'] : this.evalPaper.LM_EVA_SUM,
        ['LM_EVA_COMM'] : this.evalPaper.LM_EVA_COMM,
        ['REG_ID'] : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        ['LM_EVA_ID'] : this.evalPaper.LM_EVA_ID,
      })
      .then((response) => {
        this.$emit('submit', this.evalPaper);
        this.successAlert();
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    async insertRtnlmQs(){
      if(this.lmQs.LM_QS_TY == undefined || this.lmQs.LM_QS_TY == ''){
        this.warningAlert("유형은 필수입니다.");
        return;
      }else if(this.lmQs.LM_QS_DIC == undefined || this.lmQs.LM_QS_DIC == ''){
        this.warningAlert("난이도는 필수입니다.");
        return;
      }else if(this.lmQs.LM_QS_TY_SC == undefined || this.lmQs.LMQS_TY_SC == ''){
        this.warningAlert("배점은 필수입니다.");
        return;
      }else if(this.lmQs.LM_QS_NM == undefined || this.lmQs.LM_QS_NM == ''){
        this.warningAlert("문항내용은 필수입니다.");
        return;
      }else if(this.lmQs.LM_QS_ANS_COMM == undefined || this.lmQs.LM_QS_ANS_COMM == ''){
        this.warningAlert("정답설명은 필수입니다.");
        return;
      }


      if(this.lmVeList.length == 0){
        this.warningAlert("보기를 등록해 주세요.");
        return;
      }

      if(this.lmQs.LM_QS_TY === '10'){
        //객관식
        if(this.lmVeList.length < 2){
          this.warningAlert("객관식(단식)은 보기를 2개 이상 등록해 주세요.");
          return;
        }
      }else if(this.lmQs.LM_QS_TY === '20'){
        //서술형
        if(this.lmVeList.length != 1){
          this.warningAlert("서술형은 보기를 1개만 등록해 주세요.");
          return;
        }
      }else if(this.lmQs.LM_QS_TY === '30'){
        //체크박스형
        if(this.lmVeList.length < 2){
          this.warningAlert("객관식(복식)은 보기를 2개 이상 등록해 주세요.");
          return;
        }
      }else if(this.lmQs.LM_QS_TY === '40'){
        //ox형
        if(this.lmVeList.length != 2){
          this.warningAlert("OX형은 보기를 2개를 등록해 주세요.");
          return;
        }
      }

      let lm_ve_empty = false;

      let ans_count = 0;

      this.lmVeList.map(e => {
        if(e.LM_QS_VE_RT == undefined || e.LM_QS_VE_RT == ''){
          lm_ve_empty = true;
        }

        if(e.LM_ANS_YN == 'Y'){
          ans_count += 1;
        }
      });

      if(lm_ve_empty){
        this.warningAlert("보기내용은 필수입니다.");
        return;
      }

      if(this.lmQs.LM_QS_TY === '10'){
        //객관식
        if(ans_count != 1){
          this.warningAlert("객관식(단식)은 정답을 1개만 등록해 주세요.");
          return;
        }
      }else if(this.lmQs.LM_QS_TY === '30'){
        //체크박스형
        if(ans_count == 0){
          this.warningAlert("객관식(복식)은 정답을 1개 이상 등록해 주세요.");
          return;
        }
      }else if(this.lmQs.LM_QS_TY === '40'){
        //ox형
        if(ans_count != 1){
          this.warningAlert("OX형은 정답을 1개만 등록해 주세요.");
          return;
        }
      }

      let URLData = "api/phone/lm/evl-qs-manage/regist";
      let HEADER_SERVICE = "phone.lm.evl-qs-manage";
      let HEADER_METHOD = "regist";
      let HEADER_TYPE = "BIZ_SERVICE";
      let DATA_OBJECT = "lmVeList,delLmVeList"

      let jsonData = {
        'ASP_NEWCUST_KEY': this.aspNewCustKey,
        'LM_QS_ID' : this.lmQs.LM_QS_ID,
        'LM_QS_TY' : this.lmQs.LM_QS_TY,
        'LM_QS_NM' : this.lmQs.LM_QS_NM,
        'LM_QS_DIC' : this.lmQs.LM_QS_DIC,
        'LM_QS_TY_SC' : this.lmQs.LM_QS_TY_SC,
        'LM_QS_ANS_COMM' : this.lmQs.LM_QS_ANS_COMM,
        'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
        'lmVeList' : this.lmVeList,
        'delLmVeList' : this.delLmVeList,
      };

      await api.post(URLData, jsonData, {
        head : {
          SERVICE: HEADER_SERVICE,
          METHOD: HEADER_METHOD,
          TYPE: HEADER_TYPE,
          DATA_OBJECT : DATA_OBJECT,
        }
      })
      .then((response) => {
        this.$emit('submit', this.lmQs);
        this.successAlert();
      })
      .catch((err) => {
        this.errorAlert();
      })
    },
    successAlert(){
      this.common_alert('정상처리 되었습니다.', 'done')
      //   this.$store.commit("alertStore/openAlertDialog", {
      //   alertDialogToggle: true,
      //   msg: '정상처리 되었습니다.',
      //   iconClass: 'svg-done-lg',
      //   type: 'default'
      // })
    },
    errorAlert(){
      this.common_alert('시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.', 'error')
      // this.$store.commit("alertStore/openAlertDialog", {
      //   alertDialogToggle: true,
      //   msg: '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.',
      //   iconClass: 'svg-error-lg',
      //   type: 'default'
      // })
    },
    warningAlert(text){
      let alertText = '시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.';

      // if(text != null && text != ''){
      if(!this.mixin_isEmpty(text)){
        alertText = text;
      }
      this.common_alert(alertText, 'error')
      // this.$store.commit("alertStore/openAlertDialog", {
      //   alertDialogToggle: true,
      //   msg: alertText,
      //   iconClass: 'svg-error-lg',
      //   type: 'default'
      // })
    },
    addlmQsRow(){
      this.lmVeList.push({
        LM_QS_VE_ID : "",
        LM_QS_VE_RT : "",
        LM_ANS_YN : "N",
      });
    },
    dellmQsRow(){
      this.lmVeList = this.lmVeList.filter(item => {
        if(!item.isChecked){
          return item;
        }else{
          if(item.LM_QS_VE_ID != ''){
            this.delLmVeList.push(item);
          }
        }
      });
    },
    delEvlRstRow(){
      this.evlRstList = this.evlRstList.filter(item => {
        if(!item.isChecked){
          return item;
        }
      });
    },
    closeDialog(){
      this.$emit('hide');
    },
    async selectRtnlmVe(){
      if(this.isModify){
        let URLData = "api/phone/lm/evl-qs-manage/ve/list";
        let HEADER_SERVICE = "phone.lm.evl-qs-manage.ve";
        let HEADER_METHOD = "list";
        let HEADER_TYPE = "BIZ_SERVICE";

        let jsonData = {
          'ASP_NEWCUST_KEY': this.aspNewCustKey,
          'REG_ID' : this.$store.getters['userStore/GE_USER_ROLE'].userId,
          'LM_QS_ID' : this.lmQs.LM_QS_ID ,
        };

        await api.post(URLData, jsonData, {
          head : {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
          }
        })
        .then((response) => {
          // this.$emit('submit', this.lmQs);
          // this.successAlert();
          this.lmVeList = response.data.DATA;
        })
        .catch((err) => {
          this.errorAlert();
        })
      }
    },
  },
  computed : {
    getSc(){
      let sc = 0;
      this.propsEvlRst.map(e => {
        sc += parseInt(e.LM_QS_TY_SC)
      });
      return sc;
    },
  }
}
</script>